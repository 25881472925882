import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from "gatsby";
import { Card } from '@components/Card';
import { BlogSubscribeCard } from '@components/Blog/BlogSubscribeCard';
import { NewsListing } from './NewsListing';
import Select, { StylesConfig } from 'react-select';
import { Heading, Button } from '@atoms';
import { theme } from '@theme';

export const NewsListingCardDeck = ({ newsPosts }) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('media');
  const categories = [
    {value: 'media', label: 'Media'},
    {value: 'award', label: 'Awards'},
    {value: 'company', label: 'Company News'},
  ]

  const assetTotals = selectedCategory === 'award' ? 'awards' : selectedCategory === 'media' ? 'media assets' : selectedCategory === 'company' ? 'company news' : '';

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 1000) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <CardDeckWrapper id='news-card-deck' style={{ fontFamily: theme.font.primary }}>
      <CategoryContainer className='news-listing-card--category'>
        <div className='news-listing-card--category-tab'>
          {categories.map((category, index) => (
            <span className={`${selectedCategory === category?.value ? 'tab-active' : ''}`} onClick={() => setSelectedCategory(category?.value)}>{category?.label}</span>
          ))}
        </div>
        <p className='news-listing-card--category-total'>Workgrid has a total of <b>{newsPosts[selectedCategory].length}</b> {assetTotals}</p>
      </CategoryContainer>
      <PostsContainer>
        <NewsListing
          type={selectedCategory}
          posts={newsPosts[selectedCategory]}
        />
      </PostsContainer>
      <ScrollBtnWrapper showScrollToTop={showScrollToTop} onClick={scrollToTop}>
        <Button ctaVariant='secondary'>Back To Top</Button>
      </ScrollBtnWrapper>
    </CardDeckWrapper>
  );
};

NewsListingCardDeck.displayName = 'NewsListingCardDeck';

const CardDeckWrapper = styled.div`
  padding: 96px 60px;
  @media (max-width: 992px) {
    padding: 56px 40px;
  }
  @media (max-width: 600px) {
    padding: 40px 35px;
  }
`

const CategoryContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;

  .news-listing-card--category-tab {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 190px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F2F4F7;
      margin-right: 1px;
      cursor: pointer;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #475467;

      &:first-child {
        border-radius: 40px 0px 0px 40px;
      }

      &:last-child {
        border-radius: 0px 40px 40px 0px;
      }

      &:hover {
        opacity: 0.8;
      }

      &.tab-active {
        background: #0022B7;
        color: #FFFFFF;
      }
    }
  }

  .news-listing-card--category-total {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0022B7;
    margin: 24px 0 0;
  }
`

const PostsContainer = styled.div`
  margin: 80px auto;
  max-width: 1200px;

  @media (max-width: 600px) {
    margin-top: 32px;
  }
`;

const ScrollBtnWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 20px;
  margin-top: -45px;
  transition: opacity 400ms ease, visibility 400ms ease;
  width: fit-content;
  float: right;
  button {
    transition: filter 200ms ease;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px!important;
    line-height: 10px!important;
    padding: 14px 15px !important;
    font-weight: 700;
    min-width: unset!important;
    height: unset!important;
  } 
  @media (max-width: 768px) {
    margin-top: -100px;
  }
  ${({ showScrollToTop }) =>
  `
    visibility: ${ showScrollToTop ? 'visible' : 'hidden' };
    opacity: ${ showScrollToTop ? '100%' : '0%' };
  `}
`
