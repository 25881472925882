import React, { useState, useEffect } from 'react';
import { NewsMediaCard, NewsAwardCard, NewsCompanyCard } from '@components/Card';
import styled from 'styled-components';
import { theme } from '@theme';
import ComponentPagination from '../Pagination/Pagination';

export const NewsListing = ({ type, posts }) => {
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
  }, [type]);
  
  const maxPostsPerPage = type === 'media' ? 9 : type === 'award' ? posts.length : 11;
  const curPagePosts = posts?.slice((currentPage - 1) * maxPostsPerPage, currentPage * maxPostsPerPage);

  return (
    <NewsCardContainer style={{ fontFamily: theme.font.primary }}>
      <div className={`news-listing-card--posts-container news-listing-card--type-${type}`}>
        {curPagePosts?.length > 0 ? curPagePosts.map((post, index) => (
          <div className='news-listing-card--news-card' key={index}>
            {type === 'media' ? (
              <NewsMediaCard
              title={post?.node?.externalTitle}
              gatsbyImage={post?.node?.featuredImage?.gatsbyImageData}
              featuredImg={post?.node?.featuredImage?.file.url}
              description={post?.node?.description?.description}
              link={post?.node?.externalUrl}
              />
              ) : type === 'award' ?  (
                <NewsAwardCard
                title={post?.node?.externalTitle}
                gatsbyImage={post?.node?.featuredImage?.gatsbyImageData}
                featuredImg={post?.node?.featuredImage?.file.url}
                description={post?.node?.description?.description}
                link={post?.node?.externalUrl}
                />
                ) : (
                <NewsCompanyCard
                title={post?.node?.externalTitle}
                gatsbyImage={post?.node?.featuredImage?.gatsbyImageData}
                featuredImg={post?.node?.featuredImage?.file.url}
                description={post?.node?.description?.description}
                publishDate={post?.node?.publishDate}
                link={post?.node?.slug}
              />
            )}
          </div>
        )) : (<p className='blog-grid--blog-no-content'>No content found!</p>)}
      </div>
      <ComponentPagination 
        current={currentPage}
        total={posts.length}
        pageSize={maxPostsPerPage}
        func={setCurrentPage}
        marginTop={48}
      />
    </NewsCardContainer>
  );
};

NewsListing.displayName = 'NewsListing';

const NewsCardContainer = styled.div`
  .news-listing-card--posts-container {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;

    &.news-listing-card--type-company {
      display: flex;
      flex-direction: column;
      grid-gap: 0;
      border-bottom: 1px solid #CCCCCC;
    }

    @media (max-width: 1080px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .news-listing-card--news-card {
      & > a {
        height: 100%;
      }
    }
  }
`