import React, { useState, useEffect } from "react"
import { Layout } from "@components/Layout"
import HeroNewsroomListing from "@components/Hero/HeroNewsroomListing"
import { NewsListingCardDeck } from "@components/Newsroom"
import ConversionPanelStandard from "../../components/ConversionPanel/ConversionPanelStandard"
import { graphql } from "gatsby"
import Seo from "../../components/Seo/Seo"

// NOTE: this listing page currently uses blog posts just to demo the page because currently no Newsroom Posts exist, so the query needs to be changed to Newsroom Posts and categories actually need to match - PW

export default function NewsListingPage({ data }) {
  const {
    allContentfulNewsroomPost,
    contentfulPage,
    contentfulComponentConversionPanel,
  } = data
  const seo = contentfulPage?.pageSeo
  const newsPosts = allContentfulNewsroomPost?.edges
  const conversionPanel = contentfulComponentConversionPanel

  const mediaPosts = newsPosts.filter(
    post => post?.node?.category[0]?.title === "Media"
  )
  const awardPosts = newsPosts.filter(
    post => post?.node?.category[0]?.title === "Awards"
  )
  const companyPosts = newsPosts.filter(
    post => post?.node?.category[0]?.title === "Company News"
  )

  const filteredNewsPosts = {
    media: mediaPosts,
    award: awardPosts,
    company: companyPosts,
  }

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        metaTags={seo?.metaTags}
      />
      <HeroNewsroomListing />
      <NewsListingCardDeck newsPosts={filteredNewsPosts} />
      <ConversionPanelStandard component={conversionPanel} />
    </Layout>
  )
}

export const newsListQuery = graphql`
  query newsListingPageQuery {
    contentfulPage(internalName: { eq: "Newsroom Listing" }) {
      id
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
      }
    }
    allContentfulNewsroomPost(
      filter: {contentful_id: {nin: ["3zIH9X62AtcAq0tVp4Y8jB", "4DAr4p4fbj3OYfKBbrvu7c"]}}
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          contentful_id
          id
          title
          slug
          publishDate
          featuredImage {
            gatsbyImageData
            file {
              url
            }
          }
          description {
            description
          }
          body {
            raw
          }
          externalUrl
          externalTitle
          category {
            title
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "c4b4f8cb-c749-5dfa-b843-54ec3f7b86da" }
    ) {
      id
      textAlign
      alignContent
      background
      kicker
      noTopPadding
      noBottomPadding
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      subheading {
        raw
      }
      type
    }
  }
`
