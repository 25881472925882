import React from 'react';
import styled from 'styled-components';
import { Heading, TextLg, Text, Button } from '@atoms';
import { theme } from '@theme'
import { Link } from "gatsby";

export const BlogSubscribeCard = ({ }) => {
  return (
      <CardContainer
        data-comp={BlogSubscribeCard.displayName}
        theme={theme}
      >
          <Heading
            background='dark'
            headingSize='H4'
            style={{
              paddingBottom: '8px'
            }}
          >
            Subscribe to Workgrid News & Insights
          </Heading>
          <label style={{ color: '#cccccc', paddingTop: '10px' }}>Business Email <span style={{ color: 'red'}}>*</span></label>
          <input 
            type="email" 
            name="email" 
            placeholder="Email address"
            style={{ 
              marginTop: '10px',
              padding: '8px 20px',
              borderRadius: '35px', 
              height: '40px', 
              border: 'none',
              fontSize: '16px',
              outline: 'none'
            }}
          />
          <div style={{ paddingTop: '16px', display: 'flex', alignItems: 'flex-start' }}>
            <input style={{ marginTop: '2px', height: '36px', width: '36px', }} type="checkbox" />
            <label for="vehicle1" style={{ color: '#F0F0F0', paddingLeft: '10px', paddingTop: '11px', fontSize: '12px' }}>Yes, I subscribe to marketing communications from Workgrid about it’s products, events, and content.</label><br></br>
          </div>
          <p style={{ color: '#cccccc', paddingTop: '24px', fontWeight: 400, fontSize: '12px', lineHeight: '19px' }}>By filling out and submitting the form you undertand and agree that Workgrid processes your personal information in accordance with the <Link style={{ color: '#3EB9FF', textDecoration: 'none'}} to='/privacy-policy'>Workgrid Privacy Policy</Link>.</p>
        {/* { description && <Text>{description}</Text> } */}
        <div style={{ paddingTop: '24px', margin: '0 auto', width: '100%' }}>
          <Button style={{ width: '100%', fontWeight: 700 }} ctaVariant='secondary'>Subscribe</Button>
        </div>
      </CardContainer>
  );
};

BlogSubscribeCard.displayName = 'Card';

const CardContainer = styled.div`
  * {
    margin: 0;
    padding: 0;
  }
  ${({ theme }) =>
  `
    font-family: ${theme.font.primary};
  `}
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 26px;
  background: ${theme.colors.primary};
  border-radius: 9px;
  min-height: 480px;
  transition: box-shadow 300ms ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  @media (max-width: 1080px) {
    min-height: 520px;
  }
  @media (max-width: 768px) {
    min-height: 360px;
  }

  .card--read-more {
    ${({ theme }) =>
    `
      padding-top: 12px;
      color: ${theme.colors.primary};
      font-weight: bold !important;
    `}
  }

  .card--read-time {
    font-size: 12px;
    color: #222222;
  }
`

