import styled from 'styled-components';

export const HeroContainer = styled.div`
font-family: Roboto;
margin-top: 100px;
position: relative;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
overflow: hidden;
.hero-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.hero-news-listing--wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 30px;
  z-index: 100;

  .hero-news-listing--overlay {
    position: absolute;
    background: #101828;
    opacity: 0.7;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .hero-news-listing--burger {
    position: absolute;
    display: flex;
    padding: 24px 60px;
    width: 100%;
    left: 50%;
    top: 0;
    color: white;
    max-width: 1300px;
    transform: translateX(-50%);
    z-index: 10;
    & > * {
      margin: 0 10px 0 0;
    }
  }
  .hero-news-listing--content {
    position: relative;
    padding: 137px 0;
    z-index: 10;
    text-align: center;

    .hero-news-listing--kicker {
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 0 0 16px;
    }

    .hero-news-listing--subheading {
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      margin: 16px 0 32px;
    }
  }
}

@media (max-width: 768px) {
  .hero-news-listing--wrapper {
    .hero-news-listing--burger {
      padding: 24px 30px;
    }
    .hero-news-listing--content {
      padding: 90px 0;
    }
  }
}
`

export const HomeCrumb = styled.span`
&:hover {
  color: #DDDDDD !important;
}
`